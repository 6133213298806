import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import Mailchimp from 'react-mailchimp-form'

// Markup
class App extends Component {
  render() {
    return (
        <div className="boxed-width justify-content-center">
          <EmailContainer className="container" backgroundImage="/content/email-signup-background.png">

            <SignupCtaContent className="col-12">
              <h2>Become a better designer.</h2>
              <SignupParagraph>Become  a better designer with weekly tips through posts, videos, resources and much more. Straight to your inbox. No spam. Ever.</SignupParagraph>
              <EmailWrapper>
                <Mailchimp
                action='https://hotmail.us3.list-manage.com/subscribe/post?u=ac98385643a5c820cc83619c4&amp;id=ddb4fc0213'
                fields={[
                  {
                    name: 'EMAIL',
                    placeholder: 'Email',
                    type: 'email',
                    required: true
                  }
                ]}

                // Change predetermined language
                  messages = {
                    {
                      sending: "Sending... 🎁",
                      success: "Thank you for subscribing! 🎉",
                      error: "An unexpected internal error has occurred. 🤦‍♂️",
                      empty: "You must enter an e-mail address. 💌",
                      duplicate: "Too many subscribe attempts for this email address, cheeky. 🤙",
                      button: "Sign up"
                    }
                  }
                  // Add a personalized class
                  className='mailchimp-form'

                />
              </EmailWrapper>
            </SignupCtaContent>

          </EmailContainer>
        </div>
    );
  }
}
 
export default App;

// Styles
const EmailContainer = styled.div`
  min-height: 360px;
  display: flex;
  align-items: center;
  padding: 96px;
  background-color: #efefef;
  border-radius: 8px;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 48px;
  }
`

const SignupParagraph = styled.p `
  color: #fff;
`

const EmailWrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;

  form {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    border: none;
    font-size: 16px;
    width: auto;
    height: 48px;
    padding: 24px;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    border: solid 2px #fff;
    flex: 1;
    margin-right: 24px;
    font-family: "Avenir LT W01_65 Medium1475532", Avenir, Raleway, HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;

    @media (max-width: 768px) {
      margin-bottom: 24px;
      margin-right: 0;
    }

    ::placeholder {
      color: #fff;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      color: #fff;
    }

    ::-ms-input-placeholder {
      color: #fff;
    }
  }

  button {
    border: none;
    background-color: #FFD167;
    color: #1a1a1a;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 8px;
    margin-left: auto;
    border-color: #FFD167;
    cursor: pointer;
    transition: all 240ms ease-in-out;
    max-width: 100%;

    &:hover {
      background-color: #ffb65b;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .msg-alert {
    width: 66.6%;
    font-size: 12px;
    position: static;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 24px;

    p {
      margin-bottom: 0;
      color: #fff !important;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }
`

const SignupCtaContent = styled.div `
  max-width: 486px;
`