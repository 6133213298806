import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

// children
// classes

export default ({ data, children, classes }) => (

	<GenericPageContainer className={ "container boxed-width align-items-center justify-content-center" + ( classes || "") }>

	  <TextColumn className="col-6 col-12-t">
	    <div className="container">{children}</div>
	  </TextColumn>

	</GenericPageContainer>

)

// Styled components
const GenericPageContainer = styled.div`
  padding-top: 120px;
  padding-bottom: 144px;

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`

const TextColumn = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`