import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import SignupHero from '../components/heros/signup-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import GenericPage from '../components/containers/generic-page'
import EmailCtaSection from '../components/email-signup/email-cta-section'

class SponsorSitePage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Navigation />

        <GenericPage>
          <h1>Sponsor the website</h1>
          <p>Do you think you could get some value by being a sponsor for the site? If so you're in luck! If you want a highlighted post, section, you name it. Let's talk and see what that could look like.</p>
          <p>If you are interested, please send an email to me at <a href="mailto:callum@uiuxtips.com">callum@uiuxtips.com</a> and we can talk some more.</p>
        </GenericPage>

        <div className="">
          <EmailCtaSection />
        </div>
      </Layout>
    )
  }
}

export default SponsorSitePage

export const SponsorSiteQuery = graphql`
  query SponsorSiteQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const SectionHeadline = styled.h2 `
  margin-bottom: 48px;
`

const TipCount = styled.div `
  color: #7A89FF;
  display: inline-block;
`

const YouTubeVideo = styled.iframe `
  border-radius: 8px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`

const FeatureList = styled.ul `
  display: flex;
  flex-wrap: wrap;
`